<script lang="ts">
    import { onMount } from "svelte"; 
    import { writable } from "svelte/store";
    import { floors } from "./floors.json";
    import MapOverlay from "./MapOverlay.svelte"
    import activeFloor from "../floors.js";

    let search_input = writable("");
    const floorKeys = Object.keys(floors);
    const regions = {};
    floorKeys.forEach((floorName) => {
        floors[floorName].regions.forEach((region) => {
            regions[region.name] = floorName;
        });
    });

    let filtered_regions = Object.keys(regions);




    search_input.subscribe(
        val => {

            filtered_regions = Object.keys(regions).sort();
            filtered_regions.pop()
            filtered_regions.pop()
            filtered_regions.pop()
            filtered_regions.pop()
            filtered_regions = filtered_regions.filter(val => val.toLowerCase().includes($search_input.toLowerCase()));
        }
    )

    function find_and_switch(event) {
        const regionName = (event.target as HTMLElement).innerText;
        const floorName = regions[regionName];
        activeFloor.set(floorName);
    }
</script>



<li>
    <div class="form-control">
        <input type="text" placeholder="Search" class="input input-bordered" id="search-box-rooms" bind:value={$search_input}/>
    </div>
</li>
<div class="rooms">
    {#each filtered_regions as region}
    <li><a on:click={find_and_switch}>{region}</a></li>
    {/each}
</div>
